import { Button } from "@mui/material";
import { useLocalization } from "../hooks/useLocalization";
import AuthenticationFailedIcon from "../icons/AuthenticationFailedIcon";
import InfoPage from "../pages/common/InfoPage";

interface Props {
  errorDetails?: string;
}

const AuthenticationFailed = ({ errorDetails }: Props) => {
  const locale = useLocalization();
  const page = locale.pages.authentication_failed;

  return (
    <InfoPage
      Icon={<AuthenticationFailedIcon />}
      title={page.title}
      subtitle={page.subtitle}
      errorDetails={errorDetails}
      ActionComponent={
        <Button sx={{ width: "10rem" }} href="/" color="primary" variant="outlined">
          {page.button}
        </Button>
      }
    />
  );
};

export default AuthenticationFailed;
