import { Button, SxProps, useTheme, Theme, ButtonProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren } from "react";

interface Props {
  sx?: SxProps<Theme>;
  buttonProps?: ButtonProps;
  onClick: () => void;
}

export function LoginButton({ sx, buttonProps, children, onClick }: PropsWithChildren<Props>) {
  const theme = useTheme();
  return (
    <Button
      sx={{
        flex: 1,
        bgcolor: "white",
        border: "1px solid " + theme.palette.divider,
        borderRadius: "4px",
        lineHeight: "30px",
        alignItems: "center",
        justifyContent: "center",
        gap: 1.5,
        "&:hover": {
          cursor: "pointer",
          bgcolor: grey[50],
        },
        ...sx,
      }}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}
