import { SvgIcon, SvgIconProps } from "@mui/material";

export default function NotCompletedVerificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 62, height: 61 }} viewBox="0 0 62 61" {...props}>
      <path d="M51.1196 0.75V23.45H46.1196V5.75H12.3196V23.45H7.31958V0.75H51.1196Z" fill="#C7DBE5" />
      <path d="M27.6675 34.8748L15.4954 43.978L18.4899 47.982L30.662 38.8788L27.6675 34.8748Z" fill="#C7DBE5" />
      <path
        d="M57.7196 57.55H0.619629V13.25L29.1196 33.95L57.6196 13.25V57.55H57.7196ZM5.61963 52.55H52.7196V22.95L29.2196 39.95L5.71964 22.95V52.55H5.61963Z"
        fill="#C7DBE5"
      />
      <path
        d="M48.7195 60.2498C46.8195 60.2498 44.9195 59.8498 43.1195 58.9498C41.0195 57.9498 39.2195 56.3498 38.0195 54.3498L42.3195 51.6498C43.0195 52.8498 44.1195 53.8498 45.4195 54.4498C47.2195 55.3498 49.3195 55.4498 51.2195 54.8498C53.1195 54.2498 54.7195 52.8498 55.6195 51.0498C56.5195 49.2498 56.6195 47.1498 56.0195 45.2498C55.4195 43.3498 54.0195 41.7498 52.2195 40.8498C51.1195 40.3498 50.0195 40.0498 48.8195 40.0498L46.2195 40.1498L46.1195 35.1498L48.8195 35.0498C50.8195 35.0498 52.7195 35.4498 54.4195 36.3498C57.4195 37.8498 59.7195 40.4498 60.7195 43.6498C61.8195 46.8498 61.5195 50.2498 60.0195 53.2498C58.5195 56.2498 55.9195 58.5498 52.7195 59.5498C51.4195 59.9498 50.1195 60.2498 48.7195 60.2498Z"
        fill="#00A866"
      />
      <path
        d="M52.1195 46.2501H46.3195L41.4196 37.8501L45.9196 28.8501H51.5196L47.1195 37.6501L52.1195 46.2501Z"
        fill="#00A866"
      />
      <path d="M35.1196 11.4502H23.3196V16.4502H35.1196V11.4502Z" fill="#00A866" />
    </SvgIcon>
  );
}
