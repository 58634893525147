import { Typography } from "@mui/material";
import { useLocalization } from "../../hooks/useLocalization";
import MicrosoftIcon from "../../icons/MicrosoftIcon";
import { LoginButton } from "../LoginButton";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const MicrosoftButton = ({ disabled, onClick }: Props) => {
  const { components: locale } = useLocalization();

  return (
    <LoginButton onClick={onClick} sx={{ py: 1.1 }} buttonProps={{ disabled }}>
      <MicrosoftIcon disabled={disabled} />
      <Typography
        color={(theme) => (disabled ? theme.palette.text.secondary : theme.palette.text.primary)}
        sx={{ fontSize: "16px", fontWeight: 500 }}
      >
        {locale.microsoftButton.text}
      </Typography>
    </LoginButton>
  );
};

export default MicrosoftButton;
