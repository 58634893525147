import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AuthenticationFailedIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 254, height: 193 }} viewBox="0 0 254 193" {...props}>
      <path
        opacity="0.3"
        d="M57.3492 112.9C88.498 112.9 113.749 87.649 113.749 56.5001C113.749 25.3512 88.498 0.100098 57.3492 0.100098C26.2003 0.100098 0.949219 25.3512 0.949219 56.5001C0.949219 87.649 26.2003 112.9 57.3492 112.9Z"
        fill="#E8E8E8"
      />
      <path
        opacity="0.4"
        d="M57.3492 100.6C81.705 100.6 101.449 80.8557 101.449 56.4999C101.449 32.1441 81.705 12.3999 57.3492 12.3999C32.9935 12.3999 13.2493 32.1441 13.2493 56.4999C13.2493 80.8557 32.9935 100.6 57.3492 100.6Z"
        fill="#E8E8E8"
      />
      <path
        opacity="0.43"
        d="M57.3492 87.3002C74.3596 87.3002 88.1492 73.5106 88.1492 56.5002C88.1492 39.4898 74.3596 25.7002 57.3492 25.7002C40.3388 25.7002 26.5492 39.4898 26.5492 56.5002C26.5492 73.5106 40.3388 87.3002 57.3492 87.3002Z"
        fill="#E3E3E3"
      />
      <path
        d="M253.049 45.3999V169.2C253.049 172 250.849 174.2 248.049 174.2H45.9492C43.1492 174.2 40.9492 172 40.9492 169.2V45.3999C40.9492 42.5999 43.1492 40.3999 45.9492 40.3999H248.049C250.849 40.3999 253.049 42.5999 253.049 45.3999Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M253.049 59.2998H40.9492" stroke="#BDBDBD" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M55.0491 52.6999C56.6508 52.6999 57.9492 51.4015 57.9492 49.7999C57.9492 48.1983 56.6508 46.8999 55.0491 46.8999C53.4475 46.8999 52.1492 48.1983 52.1492 49.7999C52.1492 51.4015 53.4475 52.6999 55.0491 52.6999Z"
        fill="#BDBDBD"
      />
      <path
        d="M65.1492 52.6999C66.7508 52.6999 68.0491 51.4015 68.0491 49.7999C68.0491 48.1983 66.7508 46.8999 65.1492 46.8999C63.5475 46.8999 62.2491 48.1983 62.2491 49.7999C62.2491 51.4015 63.5475 52.6999 65.1492 52.6999Z"
        fill="#BDBDBD"
      />
      <path
        d="M75.1492 52.6999C76.7508 52.6999 78.0491 51.4015 78.0491 49.7999C78.0491 48.1983 76.7508 46.8999 75.1492 46.8999C73.5475 46.8999 72.2491 48.1983 72.2491 49.7999C72.2491 51.4015 73.5475 52.6999 75.1492 52.6999Z"
        fill="#BDBDBD"
      />
      <path
        d="M109.249 99.1999C109.249 107.3 105.749 114.6 100.149 119.7C95.2492 124.1 88.8492 126.7 81.8492 126.7C74.8492 126.7 68.3492 124 63.5492 119.7C57.9492 114.7 54.4492 107.4 54.4492 99.2999C54.4492 84.0999 66.7492 71.7999 81.9492 71.7999C97.0492 71.6999 109.249 83.9999 109.249 99.1999Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8492 102C87.5377 102 92.1492 96.493 92.1492 89.6999C92.1492 82.9068 87.5377 77.3999 81.8492 77.3999C76.1606 77.3999 71.5492 82.9068 71.5492 89.6999C71.5492 96.493 76.1606 102 81.8492 102Z"
        fill="#BDBDBD"
      />
      <path
        d="M100.149 119.6C95.2492 124 88.8492 126.6 81.8492 126.6C74.8492 126.6 68.3492 123.9 63.5492 119.6C69.1492 112 77.9492 105.5 81.8492 105.5C85.8492 105.5 94.6492 112 100.149 119.6Z"
        fill="#BDBDBD"
      />
      <path d="M170.949 79.7998H119.349V87.2998H170.949V79.7998Z" fill="#F6F6F6" />
      <path d="M231.949 95.3999H119.349V102.9H231.949V95.3999Z" fill="#F6F6F6" />
      <path d="M231.949 111.1H119.349V118.6H231.949V111.1Z" fill="#F6F6F6" />
      <path
        d="M240.449 191.9H139.149C136.149 191.9 133.749 189.5 133.749 186.5V131.5C133.749 128.5 136.149 126.1 139.149 126.1H152.549L159.449 117.4C160.349 116.3 161.949 116.3 162.849 117.4L169.749 126.1H240.349C243.349 126.1 245.749 128.5 245.749 131.5V186.5C245.849 189.4 243.449 191.9 240.449 191.9Z"
        fill="#F6F6F6"
        stroke="#BDBDBD"
      />
      <path
        d="M153.249 143.4C148.849 143.4 145.249 147 145.249 151.4V156C145.249 156.1 145.149 156.1 145.149 156.2C144.749 156.3 144.349 156.5 144.049 156.6C143.649 156.7 143.349 157.2 143.349 157.6V168.1C143.349 168.5 143.649 168.9 144.049 169.1C147.049 170.3 150.149 170.9 153.349 170.9C156.549 170.9 159.649 170.3 162.649 169.1C163.049 168.9 163.349 168.5 163.349 168.1V157.6C163.349 157.2 163.049 156.8 162.649 156.6C162.349 156.5 161.949 156.4 161.549 156.2C161.449 156.2 161.449 156.1 161.449 156V151.4C161.249 147 157.649 143.4 153.249 143.4ZM151.049 160.8C151.049 159.6 152.049 158.6 153.249 158.6C154.449 158.6 155.449 159.6 155.449 160.8C155.449 161.6 154.949 162.3 154.349 162.7V166.3C154.349 166.9 153.849 167.4 153.249 167.4C152.649 167.4 152.149 166.9 152.149 166.3V162.7C151.449 162.3 151.049 161.6 151.049 160.8ZM158.449 151.5V155.5C156.749 155.1 154.949 154.9 153.149 154.9C151.349 154.9 149.649 155.1 147.849 155.5V151.5C147.849 148.6 150.249 146.2 153.149 146.2C156.149 146.2 158.449 148.6 158.449 151.5Z"
        fill="#BDBDBD"
      />
      <path
        d="M225.049 154.3H181.949C178.449 154.3 175.549 151.4 175.549 147.9C175.549 144.4 178.349 141.5 181.949 141.5H225.049C228.549 141.5 231.449 144.4 231.449 147.9C231.449 151.5 228.549 154.3 225.049 154.3Z"
        fill="white"
      />
      <path
        d="M225.049 175.3H181.949C178.449 175.3 175.549 172.4 175.549 168.9C175.549 165.4 178.349 162.5 181.949 162.5H225.049C228.549 162.5 231.449 165.3 231.449 168.9C231.449 172.4 228.549 175.3 225.049 175.3Z"
        fill="white"
      />
      <path
        d="M187.349 166.1L188.249 167.8L190.149 168.1C190.349 168.1 190.349 168.3 190.249 168.5L188.849 169.8L189.149 171.7C189.149 171.9 189.049 172 188.849 171.9L187.149 171L185.449 171.9C185.249 172 185.149 171.9 185.149 171.7L185.449 169.8L184.049 168.5C183.949 168.4 183.949 168.2 184.149 168.1L186.049 167.8L186.949 166.1C187.049 165.9 187.249 165.9 187.349 166.1Z"
        fill="#BDBDBD"
      />
      <path
        d="M195.549 166.1L196.449 167.8L198.349 168.1C198.549 168.1 198.549 168.3 198.449 168.5L197.049 169.8L197.349 171.7C197.349 171.9 197.249 172 197.049 171.9L195.349 171L193.649 171.9C193.449 172 193.349 171.9 193.349 171.7L193.649 169.8L192.249 168.5C192.149 168.4 192.149 168.2 192.349 168.1L194.249 167.8L195.149 166.1C195.249 165.9 195.449 165.9 195.549 166.1Z"
        fill="#BDBDBD"
      />
      <path
        d="M203.649 166.1L204.549 167.8L206.449 168.1C206.649 168.1 206.649 168.3 206.549 168.5L205.149 169.8L205.449 171.7C205.449 171.9 205.349 172 205.149 171.9L203.449 171L201.749 171.9C201.549 172 201.449 171.9 201.449 171.7L201.749 169.8L200.349 168.5C200.249 168.4 200.249 168.2 200.449 168.1L202.349 167.8L203.249 166.1C203.349 165.9 203.649 165.9 203.649 166.1Z"
        fill="#BDBDBD"
      />
      <path
        d="M211.849 166.1L212.749 167.8L214.649 168.1C214.849 168.1 214.849 168.3 214.749 168.5L213.349 169.8L213.649 171.7C213.649 171.9 213.549 172 213.349 171.9L211.649 171L209.949 171.9C209.749 172 209.649 171.9 209.649 171.7L209.949 169.8L208.549 168.5C208.449 168.4 208.449 168.2 208.649 168.1L210.549 167.8L211.449 166.1C211.549 165.9 211.749 165.9 211.849 166.1Z"
        fill="#BDBDBD"
      />
      <path
        d="M219.949 166.1L220.849 167.8L222.749 168.1C222.949 168.1 222.949 168.3 222.849 168.5L221.449 169.8L221.749 171.7C221.749 171.9 221.649 172 221.449 171.9L219.749 171L218.049 171.9C217.849 172 217.749 171.9 217.749 171.7L218.049 169.8L216.649 168.5C216.549 168.4 216.549 168.2 216.749 168.1L218.649 167.8L219.549 166.1C219.649 165.9 219.949 165.9 219.949 166.1Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.126 70.5842C225.688 81.6232 217.38 88.5653 202.358 93.8003C187.336 88.5653 179.028 81.6232 174.59 70.5842C170.265 59.7728 169.696 44.9783 170.379 24.8349L202.358 16.8687L234.337 24.8349C235.02 44.9783 234.451 59.7728 230.126 70.5842Z"
        fill="#F6F6F6"
        stroke="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.359 85.0376C191.092 80.9407 184.833 75.5919 181.419 67.2842C178.005 58.8627 177.549 46.9132 177.891 31.6635L202.245 25.6318L226.599 31.6635C227.054 46.9132 226.485 58.8627 223.071 67.2842C219.884 75.5919 213.625 80.9407 202.359 85.0376Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.286 42.2467L188.93 60.2278C188.019 61.7072 189.157 63.6419 190.864 63.6419H211.691C213.398 63.6419 214.536 61.7072 213.625 60.2278L203.269 42.2467C202.359 40.6534 200.196 40.6534 199.286 42.2467Z"
        fill="#F6F6F6"
        stroke="#BDBDBD"
      />
      <path d="M201.337 49.9854V56.1308" stroke="#BDBDBD" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M201.336 59.0894C201.839 59.0894 202.246 58.6818 202.246 58.179C202.246 57.6762 201.839 57.2686 201.336 57.2686C200.833 57.2686 200.426 57.6762 200.426 58.179C200.426 58.6818 200.833 59.0894 201.336 59.0894Z"
        fill="#BDBDBD"
      />
      <path
        d="M65.6525 164.177H24.7186C21.4155 164.177 18.8203 161.582 18.8203 158.279V141.41C18.8203 138.107 21.4155 135.512 24.7186 135.512H65.6525C68.9555 135.512 71.5508 138.107 71.5508 141.41V158.279C71.5508 161.582 68.9555 164.177 65.6525 164.177Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8748 145.186H47.8401C51.0251 145.186 53.7383 147.781 53.7383 151.084C53.7383 154.269 51.1431 156.982 47.8401 156.982H38.8748"
        stroke="#BDBDBD"
        strokeMiterlimit="10"
      />
      <path d="M43.7114 140.349L38.8749 145.186L43.7114 150.022" stroke="#BDBDBD" strokeMiterlimit="10" />
    </SvgIcon>
  );
}
