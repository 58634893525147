import { Grid, GridProps, Paper, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import CoverImage from "../../images/bg_img.svg";
import BreakpointComponent from "../../components/Breakpoint";

interface Props {
  sx?: SxProps;
  containerProps?: GridProps;
}

export default function StyledPage({ sx, containerProps, children }: PropsWithChildren<Props>) {
  return (
    <>
      <BreakpointComponent from="sm">
        <Grid container sx={(theme) => ({ ...infoPageStyle(theme), ...sx })} {...containerProps}>
          <Paper
            sx={{
              width: 510,
              borderRadius: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
              p: "40px 32px 32px 32px",
            }}
          >
            {children}
          </Paper>
        </Grid>
      </BreakpointComponent>
      <BreakpointComponent to="sm">
        <Grid container sx={{ ...sx, bgcolor: "white", px: 4 }} {...containerProps}>
          {children}
        </Grid>
      </BreakpointComponent>
    </>
  );
}

export function infoPageStyle(theme: Theme): SxProps {
  return {
    height: "100%",
    background: `url(${CoverImage}) no-repeat center center fixed`,
    backgroundSize: "cover",
    backgroundColor: theme.palette.background.default,
  };
}
