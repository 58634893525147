import { Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { redirectToMicrosoftSignIn } from "../auth/authHelper";
import { useLocalization } from "../hooks/useLocalization";
import EntriliaLogo from "../icons/EntriliaLogo";
import { AnyObject } from "../types";
import { getStateParams } from "../variables";
import { ReferenceVariables } from "./RedirectKeys";
import MicrosoftButton from "./common/MicrosoftButton";
import NeedHelp from "./common/NeedHelp";
import StyledPage from "./common/StyledPage";

export default function SignInPage() {
  const { pages: locale } = useLocalization();
  const { oauthState, withAccountSelection } = useOauthState();

  const handleRedirectToMicrosoft = useCallback(
    () => redirectToMicrosoftSignIn(withAccountSelection, oauthState),
    [oauthState, withAccountSelection]
  );

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Stack spacing={2}>
        <Stack alignItems="center">
          <EntriliaLogo />
        </Stack>
        <Stack alignItems="center" pt={2} spacing={1}>
          <Typography variant="h6">{locale.signin.text}</Typography>
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {locale.signin.disclaimer}
          </Typography>
        </Stack>
        <MicrosoftButton onClick={handleRedirectToMicrosoft} />
        <Stack alignItems="center" mt={1}>
          <NeedHelp />
        </Stack>
      </Stack>
    </StyledPage>
  );
}

function useOauthState() {
  const [params] = useSearchParams();
  const verificationId = params.get(ReferenceVariables.VerificationId);
  const returnType = params.get(ReferenceVariables.ReturnType);
  const redirectState = params.get(ReferenceVariables.RedirectUrlState);
  const propmt = params.get(ReferenceVariables.Prompt);

  const oauthState = React.useMemo(() => {
    const state: AnyObject = {};
    if (verificationId !== undefined) {
      Object.assign(state, { [ReferenceVariables.VerificationId]: verificationId });
    }
    if (redirectState !== undefined && redirectState !== null) {
      const stateParams = getStateParams(redirectState);
      Object.assign(state, stateParams);
    }
    if (returnType !== undefined) {
      Object.assign(state, { [ReferenceVariables.ReturnType]: returnType });
    }
    return state;
  }, [verificationId, redirectState, returnType]);

  return { oauthState, withAccountSelection: propmt === "select_account" };
}
