export enum RedirectKeys {
  RedirectCode = "code",
  RedirectState = "state",
}

export enum ReferenceVariables {
  InvitationId = "invitationId",
  VerificationId = "verificationId",
  ReturnType = "returnType",
  RedirectUrlState = "state",
  Prompt = "prompt",
  Idp = "idp",
}
