import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ApiError, ApiResponse } from "../api/types";
import { AsyncFunc } from "../types";

const useFetch = <TData>(
  fetch: AsyncFunc<ApiResponse<TData>>,
  onSetData?: (data: TData) => void
): [TData | undefined, ApiError | undefined, Dispatch<SetStateAction<TData | undefined>>] => {
  const [data, setData] = useState<TData>();
  const [error, setError] = useState<ApiError>();

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      try {
        const resp = await fetch();
        if (ignore) {
          return;
        }

        if (resp.success) {
          setData(resp.data);
          onSetData?.(resp.data);
        } else if (resp.error) {
          setError(resp.error);
        } else {
          setError({ message: "Unknown error", code: 0, type: "General" });
        }
      } catch (err) {
        if (ignore) {
          return;
        }

        if (axios.isAxiosError(err)) {
          const statusCode = err.response?.status;
          const message = `HTTP Error ${statusCode}: ${err.message}`;
          setError({ message, code: statusCode || 0, type: "General" });
        } else {
          setError({ message: "Unknown error", code: 0, type: "General" });
        }
      }
    };

    fetchData();

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  return [data, error, setData];
};

export default useFetch;
