import { PropsWithChildren } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Breakpoint, Breakpoints } from "@mui/material";

interface Props {
  from?: Breakpoint | number;
  to?: Breakpoint | number;
}

export default function BreakpointComponent(props: PropsWithChildren<Props>) {
  const getMediaQuery = ({ from, to }: Props, breakpoints: Breakpoints) => {
    if (from === undefined) {
      return to === undefined ? "" : breakpoints.down(to);
    }
    if (to === undefined) {
      return breakpoints.up(from);
    }
    return breakpoints.between(from, to);
  };

  const theme = useTheme();
  const shouldRender = useMediaQuery(getMediaQuery(props, theme.breakpoints));
  return shouldRender ? <>{props.children}</> : null;
}
