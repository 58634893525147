import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { initAppInsights } from "./logging";
import { themeOptions } from "./theme";
import SignUpPage from "./pages/SignUpPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import VerificationNotCompletedPage from "./pages/VerificationNotCompletedPage";
import SignInPage from "./pages/SignInPage";
import SignInAuthPage from "./pages/SignInAuthPage";
import { setUpAxios } from "./auth/axiosHelper";
import SignUpAuthPage from "./pages/SignUpAuthPage";
import EmailSentPage from "./pages/EmailSentPage";
import React from "react";
import { UserContextProvider } from "./contexts/UserContext";
import InvalidLinkPage from "./pages/InvalidLinkPage";
import LogoutPage from "./pages/LogoutPage";
import LoggedOutPage from "./pages/LoggedOutPage";
import ErrorPage from "./pages/ErrorPage";
import UserNotAuthorizedPage from "./pages/UserNotAuthorizedPage";

initAppInsights({ appId: "LoginPortal" });
setUpAxios();

const theme = createTheme(themeOptions);
export enum PageNames {
  SignIn = "signin",
  Logout = "logout",
  LoggedOut = "loggedout",
  InvalidLink = "invalidlink",
  Verification = "verification",
  EmailSent = "emailsent",
  VerificationNotCompleted = "verificationnotcompleted",
  Error = "error",
  NotAuthorized = "unauthorized",
}

export default function Entry() {
  const [email, setEmail] = React.useState<string>();

  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider user={{ email, update: setEmail }}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Navigate to={"signin"} />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path={PageNames.SignIn} element={<SignInPage />} />
          <Route path="oauth_signin" element={<SignInAuthPage />} />
          <Route path="oauth_signup" element={<SignUpAuthPage />} />
          <Route path={PageNames.Logout} element={<LogoutPage />} />
          <Route path={PageNames.LoggedOut} element={<LoggedOutPage />} />
          <Route path={PageNames.EmailSent} element={<EmailSentPage />} />
          <Route path={PageNames.Verification} element={<VerifyEmailPage />} />
          <Route path={PageNames.InvalidLink} element={<InvalidLinkPage />} />
          <Route path={PageNames.VerificationNotCompleted} element={<VerificationNotCompletedPage />} />
          <Route path={PageNames.NotAuthorized} element={<UserNotAuthorizedPage />} />
          <Route path={PageNames.Error} element={<ErrorPage />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </UserContextProvider>
    </ThemeProvider>
  );
}
