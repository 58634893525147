import { useSearchParams } from "react-router-dom";
import { IdpType, idpTypes } from "../api/types";
import { RedirectKeys, ReferenceVariables } from "../pages/RedirectKeys";

export function useOauthState(): [string | undefined, string | undefined, IdpType | undefined] {
  const [params] = useSearchParams();
  return getOauthState(params);
}

export function getOauthState(params: URLSearchParams): [string | undefined, string | undefined, IdpType | undefined] {
  const authCode = params.get(RedirectKeys.RedirectCode) || undefined;
  const oauthState = params.get(RedirectKeys.RedirectState) || "";
  const stateObj = getStateParams(oauthState);

  const invitationId = stateObj[ReferenceVariables.InvitationId];
  const idpFromState = stateObj[ReferenceVariables.Idp] as IdpType;
  const idp = idpTypes.includes(idpFromState) ? idpFromState : undefined;

  return [authCode, invitationId, idp];
}

function getStateParams(oauthState: string): Record<string, string> {
  try {
    return JSON.parse(oauthState);
  } catch {
    return {};
  }
}
