import { SvgIcon, SvgIconProps } from "@mui/material";

interface Props extends SvgIconProps {
  disabled?: boolean;
}

export default function MicrosoftIcon(props: Props) {
  return (
    <SvgIcon sx={{ width: 23, height: 23, opacity: props.disabled ? 0.5 : 1 }} viewBox="0 0 23 23" {...props}>
      <path d="M0 0H23V23H0V0Z" fill="#F3F3F3" />
      <path d="M1 1H11V11H1V1Z" fill="#F35325" />
      <path d="M12 1H22V11H12V1Z" fill="#81BC06" />
      <path d="M1 12H11V22H1V12Z" fill="#05A6F0" />
      <path d="M12 12H22V22H12V12Z" fill="#FFBA08" />
    </SvgIcon>
  );
}
