import { SvgIcon, SvgIconProps } from "@mui/material";

export default function LoggedOutIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 222, height: 189, fill: "none" }} viewBox="0 0 222 189" {...props}>
      <path
        d="M200.001 50.3857H6.12018C3.07036 50.3857 0.674072 52.782 0.674072 55.8318V174.121C0.674072 177.171 3.07036 179.567 6.12018 179.567H200.001C203.051 179.567 205.448 177.171 205.448 174.121V55.8318C205.448 52.782 202.942 50.3857 200.001 50.3857Z"
        fill="#F6F6F6"
        stroke="#BDBDBD"
      />
      <path
        d="M215.793 34.5928H21.9119C18.8621 34.5928 16.4658 36.9891 16.4658 40.0389V158.328C16.4658 161.378 18.8621 163.774 21.9119 163.774H215.793C218.843 163.774 221.239 161.378 221.239 158.328V40.0389C221.239 37.098 218.734 34.5928 215.793 34.5928Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3614 104.262C83.831 92.0419 71.1646 83.5679 58.6765 83.5679V87.2251C63.0473 87.2251 67.3289 88.3847 71.343 90.4363C68.1317 94.3611 66.5261 99.8915 67.0613 105.6C67.5073 110.863 69.7373 115.412 73.2162 118.713C68.7561 121.389 63.7609 122.905 58.6765 122.905V126.562C71.2538 126.562 83.831 117.999 89.3614 105.868L89.7182 105.154L89.3614 104.262ZM76.249 116.572C73.0378 113.985 70.9862 109.882 70.5401 105.244C70.0941 100.338 71.6106 95.5207 74.465 92.2203C79.1926 95.3423 83.2066 99.8023 85.7042 105.065C83.5634 109.525 80.263 113.539 76.249 116.572Z"
        fill="#BDBDBD"
      />
      <path
        d="M60.1026 117.106L55.9994 109.97C55.9102 109.881 55.821 109.881 55.7318 109.881L52.9666 111.487C52.8774 111.576 52.8774 111.665 52.8774 111.754L54.6614 114.787C54.7506 114.966 54.6614 115.144 54.483 115.144L41.9058 117.552C41.7274 117.552 41.549 117.374 41.6382 117.196L47.347 104.351C47.347 104.262 47.347 104.172 47.347 104.172L41.549 91.506C41.4598 91.3276 41.6382 91.1492 41.8166 91.1492L54.3938 93.5576C54.5722 93.5576 54.6614 93.8252 54.5722 93.9144L52.8774 96.9472C52.7882 97.0364 52.8774 97.2148 52.9666 97.304L55.4642 98.7312C55.6426 98.8204 55.9102 98.7312 56.0886 98.5528L60.0134 91.5952C60.1918 91.2384 60.0134 90.8816 59.6566 90.7924L36.197 86.3323C35.751 86.2431 35.3941 86.6891 35.5726 87.0459L43.333 104.262L35.6618 121.566C35.4834 121.923 35.8402 122.369 36.2862 122.28L59.7458 117.82C60.1026 117.82 60.281 117.463 60.1026 117.106Z"
        fill="#BDBDBD"
      />
      <path
        d="M77.852 97.3047C76.6924 97.3047 75.7112 98.2859 75.7112 99.4455C75.7112 100.605 76.6924 101.586 77.852 101.586C79.0116 101.586 79.9928 100.605 79.9928 99.4455C79.9036 98.2859 79.0116 97.3047 77.852 97.3047Z"
        fill="#BDBDBD"
      />
      <path d="M174.954 92.4307H116.245V101.471H174.954V92.4307Z" fill="#F6F6F6" />
      <path d="M207.413 108.66H116.245V117.7H207.413V108.66Z" fill="#F6F6F6" />
      <path
        d="M115.536 187.902H43.4358C40.6358 187.902 38.4358 185.702 38.4358 182.902V156.802C38.4358 154.002 40.6358 151.802 43.4358 151.802H115.536C118.336 151.802 120.536 154.002 120.536 156.802V182.902C120.536 185.702 118.236 187.902 115.536 187.902Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.436 169.902C107.436 173.702 105.736 177.102 103.136 179.502C100.836 181.502 97.8357 182.802 94.5357 182.802C91.2357 182.802 88.2357 181.602 85.9357 179.502C83.3357 177.102 81.6357 173.702 81.6357 169.902C81.6357 162.802 87.4357 157.002 94.5357 157.002C101.636 157.002 107.436 162.702 107.436 169.902Z"
        fill="white"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5356 171.102C97.0209 171.102 99.0356 168.685 99.0356 165.702C99.0356 162.72 97.0209 160.302 94.5356 160.302C92.0504 160.302 90.0356 162.72 90.0356 165.702C90.0356 168.685 92.0504 171.102 94.5356 171.102Z"
        fill="#BDBDBD"
      />
      <path
        d="M103.136 179.502C100.836 181.502 97.8358 182.802 94.5358 182.802C91.2358 182.802 88.2358 181.602 85.9358 179.502C88.5358 175.902 92.6358 172.902 94.5358 172.902C96.4358 172.802 100.536 175.902 103.136 179.502Z"
        fill="#BDBDBD"
      />
      <path d="M60.9357 160.702L51.6357 170.002L60.5357 179.002" fill="white" />
      <path
        d="M60.9357 160.702L51.6357 170.002L60.5357 179.002"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M69.5358 160.702L60.2358 170.002L69.1358 179.002" fill="white" />
      <path
        d="M69.5358 160.702L60.2358 170.002L69.1358 179.002"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.67 40.1475C177.67 41.8902 177.561 43.633 177.343 45.3758C174.838 64.546 158.391 79.2505 138.567 79.2505C133.121 79.2505 127.784 78.0524 123.1 76.0918C122.229 75.765 121.466 75.3293 120.704 74.8936L106.871 81.7557C104.474 83.2806 101.316 81.429 101.86 78.8148L105.346 60.7338C104.148 58.7732 103.058 56.7036 102.296 54.5252C101.642 52.8914 101.098 51.2575 100.662 49.5148C99.8996 46.465 99.4639 43.3062 99.4639 40.0386C99.4639 23.2646 109.92 8.99578 124.734 3.44075C128.982 1.80692 133.665 0.935547 138.567 0.935547C149.023 0.935547 158.5 5.07459 165.471 11.7188C172.986 18.9077 177.67 29.0374 177.67 40.1475Z"
        fill="#F6F6F6"
        stroke="#BDBDBD"
      />
      <path
        d="M153.271 49.2966L142.814 59.7531C140.091 62.4762 135.516 62.4762 132.793 59.7531L118.415 45.3754C115.584 42.6524 115.584 38.0776 118.415 35.3546L132.793 20.9769C135.516 18.2538 140.091 18.2538 142.814 20.9769L153.271 31.4334"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.744 39.493L156.099 34.9182C155.337 34.3736 154.357 34.9182 154.357 35.7896V44.8302C154.357 45.7015 155.337 46.2461 156.099 45.7015L162.744 41.1268C163.397 40.909 163.397 39.9287 162.744 39.493Z"
        fill="#BDBDBD"
      />
      <path
        d="M155.558 40.4736H145.973"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
