import { Grid, Typography } from "@mui/material";
import StyledPage from "./common/StyledPage";
import VerificationLinkNotValid from "../icons/VerificationLinkNotValid";
import { useLocalization } from "../hooks/useLocalization";

export default function InvalidLinkPage() {
  const { pages: locale } = useLocalization();

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Grid container sx={{ gap: 3 }}>
        <Grid container sx={{ justifyContent: "center" }}>
          <VerificationLinkNotValid />
        </Grid>
        <Grid container sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", pt: 1, gap: 1 }}>
          <Typography variant="h6">{locale.invalid_link.header}</Typography>
          <Typography variant="body1" color="secondary" sx={{ textAlign: "center" }}>
            {locale.invalid_link.text}
          </Typography>
        </Grid>
      </Grid>
    </StyledPage>
  );
}
