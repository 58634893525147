import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 66, height: 65 }} viewBox="0 0 66 65" {...props}>
      <path
        d="M33.0001 36.9497C27.0001 36.9497 22.1001 31.1497 22.1001 23.9497C22.1001 16.7497 27.0001 10.9497 33.0001 10.9497C39.0001 10.9497 43.9001 16.7497 43.9001 23.9497C43.9001 31.1497 39.0001 36.9497 33.0001 36.9497ZM33.0001 15.8497C29.7001 15.8497 27.0001 19.4497 27.0001 23.9497C27.0001 28.4497 29.7001 32.0497 33.0001 32.0497C36.3001 32.0497 39.0001 28.4497 39.0001 23.9497C39.1001 19.5497 36.4001 15.8497 33.0001 15.8497Z"
        fill="#00A866"
      />
      <path
        d="M33.0003 38.4497C28.9003 38.4497 18.2003 49.3497 15.3003 59.0497H20.4003C23.3003 51.3497 30.8003 44.4497 33.0003 43.3497C35.2003 44.4497 42.7003 51.3497 45.6003 59.0497H50.7003C47.9003 49.2497 37.2003 38.4497 33.0003 38.4497Z"
        fill="#00A866"
      />
      <path
        d="M33.0001 64.9498C15.1001 64.9498 0.600098 50.3498 0.600098 32.5498C0.600098 14.7498 15.1001 0.0498047 33.0001 0.0498047C50.9001 0.0498047 65.4001 14.6498 65.4001 32.4498C65.4001 50.2498 50.8001 64.9498 33.0001 64.9498ZM33.0001 5.0498C17.9001 5.0498 5.6001 17.3498 5.6001 32.4498C5.6001 47.5498 17.9001 59.8498 33.0001 59.8498C48.1001 59.8498 60.4001 47.6498 60.4001 32.4498C60.4001 17.2498 48.1001 5.0498 33.0001 5.0498Z"
        fill="#C7DBE5"
      />
    </SvgIcon>
  );
}
