import { SvgIcon, SvgIconProps } from "@mui/material";

export default function VerificationLinkNotValid(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 62, height: 63 }} viewBox="0 0 62 63" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.548 3.62952L58.868 6.9495C63.0406 11.1222 63.0406 17.9539 58.868 22.1266L50.0735 30.921C46.7838 34.2158 41.8341 34.902 37.8431 32.9998L53.4137 17.4292C54.9526 15.8903 54.9526 13.3776 53.4137 11.8437L50.4015 8.8315C48.8626 7.2926 46.3549 7.2926 44.816 8.8315L29.3413 24.3012C27.6409 20.3758 28.3826 15.6228 31.5765 12.429L40.3759 3.62952C44.5486 -0.543172 51.3753 -0.543172 55.548 3.62952ZM3.12952 56.048L6.4495 59.368C10.6222 63.5406 17.4489 63.5406 21.6266 59.368L30.421 50.5735C33.7158 47.2838 34.402 42.3341 32.4998 38.3431L16.9292 53.9137C15.3903 55.4526 12.8776 55.4526 11.3437 53.9137L8.3315 50.9015C6.7926 49.3626 6.7926 46.8499 8.3315 45.311L23.8063 29.8413C19.8758 28.1409 15.1279 28.8826 11.929 32.0815L3.12952 40.8759C-1.04317 45.0486 -1.04317 51.8753 3.12952 56.048Z"
        fill="#C7DBE5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3309 59.4334L43.55 58.7674L41.4712 48.7318L38.2572 49.4079L40.3309 59.4334ZM18.3574 1.58594L21.5412 0.798828L24.0387 10.7184L20.855 11.5257L18.3574 1.58594ZM51.9004 53.8783L54.186 51.522L46.8346 44.3875L44.549 46.7489L51.9004 53.8783ZM59.3477 43.2674L60.1953 40.0988L50.306 37.4448L49.4533 40.6185L59.3477 43.2674ZM1.2832 20.9206L2.19645 17.7671L12.0353 20.5674L11.1423 23.731L1.2832 20.9206ZM6.7526 9.16439L9.07861 6.84342L16.314 14.0838L13.998 16.4098L6.7526 9.16439Z"
        fill="#00A966"
      />
    </SvgIcon>
  );
}
