import { Button, Popover, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useLocalization } from "../../hooks/useLocalization";

const NeedHelp = () => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const { components: locale } = useLocalization();

  return (
    <>
      <Button ref={buttonRef} color="secondary" variant="text" onClick={() => setPopoverOpen((isOpen) => !isOpen)}>
        {locale.needHelp.button}
      </Button>

      <Popover
        anchorEl={buttonRef.current}
        open={isPopoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Stack width="19rem" spacing={1} p={2}>
          <Typography variant="caption" color="text.secondary">
            {locale.needHelp.helpText1}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {locale.needHelp.helpText2}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {locale.needHelp.helpText3}
          </Typography>
        </Stack>
      </Popover>
    </>
  );
};

export default NeedHelp;
