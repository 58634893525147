import { Button } from "@mui/material";
import { useLocalization } from "../hooks/useLocalization";
import InfoPage from "./common/InfoPage";
import LoggedOutIcon from "../icons/LoggedOutIcon";
import { PageNames } from "../Entry";

export default function LoggedOutPage() {
  const locale = useLocalization();
  const page = locale.pages.loggedout;

  return (
    <InfoPage
      Icon={<LoggedOutIcon />}
      title={page.title}
      subtitle={page.subtitle}
      ActionComponent={
        <Button variant="outlined" color="primary" href={`${PageNames.SignIn}`} sx={{ width: "10rem" }}>
          {page.button}
        </Button>
      }
    />
  );
}
