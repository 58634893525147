export type ApiErrorType = "General" | "NotFound" | "AlreadyExist" | "EtagMismatch";

export interface ApiError {
  message?: string;
  code: number;
  type: ApiErrorType;
}

export type ApiResponse<TData> =
  | {
      success: true;
      data: TData;
      error?: ApiError;
    }
  | {
      success: false;
      data?: TData;
      error?: ApiError;
    };

export interface SignupTokenData {
  email: string;
  isEmailVerified: boolean;
}

export interface SignupTokenResponse {
  data: SignupTokenData;
}
export interface InvitationResponse {
  isAcceptable: boolean;
}

export const idpTypes = ["Microsoft"] as const;
export type IdpType = (typeof idpTypes)[number];

export interface Identity {
  id: string;
  email: string;
}
export interface User {
  isEmailVerified: boolean;
}

export interface LogoutData {
  redirectToLogoutUrl: string;
}
