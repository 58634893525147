import { SvgIcon, SvgIconProps } from "@mui/material";

export default function VerifyEmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 64, height: 55 }} viewBox="0 0 64 55" {...props}>
      <path
        d="M61.0001 45.6499H0.100098V0.649902H61.0001V45.6499ZM5.1001 40.6499H56.0001V5.6499H5.1001V40.6499Z"
        fill="#C7DBE5"
      />
      <path
        d="M31.6 23.3498L30.4 22.7498C21.4 18.0498 2.10005 5.74981 1.30005 5.24981L4.00005 1.0498C4.20005 1.1498 22.3 12.6498 31.5 17.6498C37.7 13.9498 56.9 1.2498 57.2 1.0498L60 5.24981C59.1 5.84981 37.3 20.2498 32.9 22.7498L31.6 23.3498Z"
        fill="#C7DBE5"
      />
      <path
        d="M49.2 46.7498C45.8 46.7498 43 43.9498 43 40.5498V38.2498C43 34.8498 45.8 32.0498 49.2 32.0498C52.6 32.0498 55.4 34.8498 55.4 38.2498V40.5498C55.4 44.0498 52.6 46.7498 49.2 46.7498ZM49.2 37.1498C48.6 37.1498 48 37.6498 48 38.3498V40.6498C48 41.2498 48.5 41.8498 49.2 41.8498C49.9 41.8498 50.4 41.3498 50.4 40.6498V38.3498C50.4 37.6498 49.8 37.1498 49.2 37.1498Z"
        fill="#00A866"
      />
      <path
        d="M49.1002 54.3499C41.0002 54.3499 34.3002 47.7499 34.2002 39.6499C34.2002 35.6499 35.7002 31.9499 38.5002 29.0499C41.3002 26.2499 45.0002 24.6499 49.0002 24.6499C53.0002 24.6499 56.7002 26.1499 59.5002 28.9499C62.4002 31.7499 63.9002 35.5499 63.9002 39.5499V39.9499C63.9002 43.7499 60.8002 46.7499 57.1002 46.7499C53.4002 46.7499 50.3002 43.6499 50.3002 39.9499V33.3499H55.3002V39.9499C55.3002 40.9499 56.1002 41.7499 57.1002 41.7499C58.1002 41.7499 58.9002 40.9499 58.9002 39.9499V39.5499C58.9002 36.8499 57.8002 34.3499 55.9002 32.4499C54.0002 30.5499 51.6002 29.5499 49.0002 29.5499C49.0002 29.5499 49.0002 29.5499 48.9002 29.5499C46.3002 29.5499 43.8002 30.6499 41.9002 32.4499C40.0002 34.3499 39.0002 36.8499 39.1002 39.4499C39.2002 44.8499 43.6002 49.2499 49.0002 49.2499C50.9002 49.2499 52.8002 48.6499 54.4002 47.6499L57.1002 51.8499C54.9002 53.5499 52.1002 54.3499 49.1002 54.3499C49.2002 54.3499 49.1002 54.3499 49.1002 54.3499Z"
        fill="#00A866"
      />
    </SvgIcon>
  );
}
