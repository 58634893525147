import { Box, Grid, Stack, Typography } from "@mui/material";
import AuthLoaderSvg from "./AuthLoaderSvg";

interface Props {
  title: string;
  subtitle: string;
}

export default function FullScreenLoader({ title, subtitle }: Props) {
  return (
    <Grid container sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", spacing: 3 }}>
      <Grid item>
        <Box mt={-20} width={240}>
          <AuthLoaderSvg />
        </Box>
      </Grid>
      <Grid item>
        <Stack spacing={1} alignItems="center">
          <Typography variant="h3">{title}</Typography>
          <Typography variant="body1">{subtitle}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
