import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../utils/typeHelper";

interface User {
  email?: string;
  update: (value: string) => void;
}

const UserContext = React.createContext<User | undefined>(undefined);

interface Props {
  user: User;
}

export const UserContextProvider = ({ user, children }: PropsWithChildren<Props>) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);

export const useUserContext = () => {
  const user = useContext(UserContext);
  return defined(user);
};
