import { ThemeOptions } from "@mui/material/styles/createTheme";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#00A866",
      dark: "#009066",
      light: "#32CA7F",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#647381",
      dark: "#303A48",
      light: "#8E9BA9",
      contrastText: "#FFFFFF",
    },
    action: {
      active: "#647381",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabled: "rgba(0, 0, 0, 0.26)",
    },
    text: {
      primary: "#23343B",
      secondary: "#647381",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    info: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "#64B6F7",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#F44336",
      dark: "#E31B0C",
      light: "#F88078",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FF9800",
      dark: "#C77700",
      light: "#FFB547",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#4CAF50",
      dark: "#3B873E",
      light: "#7BC67E",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FAFAFA",
      paper: "#FFFFFF",
    },
    divider: "#E5E6E9",
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        outlinedSecondary: ({ theme }) => ({
          borderColor: theme.palette.divider,
          ":hover": {
            borderColor: theme.palette.divider,
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.divider,
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "14px",
          "&.MuiOutlinedInput-input:not(.MuiInputBase-inputSizeSmall):not(.MuiAutocomplete-input)": {
            padding: "15px 14px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: "7.5px 9px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          ...theme.typography.body2,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: "14px",
          fontWeight: "500",
          "&.Mui-selected": {
            color: theme.palette.primary.main,
            ".MuiTypography-root": {
              color: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:last-child": {
            paddingBottom: theme.spacing(2),
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.divider,
        }),
      },
    },
  },
  typography: {
    h1: { fontSize: 32, fontWeight: 600, fontFamily: "Epilogue", lineHeight: "120%", letterSpacing: "-1.5px" },
    h2: { fontSize: 28, fontWeight: 600, fontFamily: "Epilogue", lineHeight: "120%", letterSpacing: "-0.5px" },
    h3: { fontSize: 24, fontWeight: 600, fontFamily: "Epilogue", lineHeight: "120%" },
    h4: { fontSize: 20, fontWeight: 600, fontFamily: "Epilogue", lineHeight: "140%", letterSpacing: "0.25px" },
    h5: { fontSize: 18, fontWeight: 600, fontFamily: "Epilogue", lineHeight: "140%" },
    h6: { fontSize: 15, fontWeight: 600, fontFamily: "Epilogue", lineHeight: "140%", letterSpacing: "0.15px" },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "140%",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "140%",
      letterSpacing: "0.1px",
    },
    body1: { fontSize: 14, fontWeight: 400, fontFamily: "Roboto", lineHeight: "140%", letterSpacing: "0.15px" },
    body2: { fontSize: 13, fontWeight: 400, fontFamily: "Roboto", lineHeight: "140%", letterSpacing: "0.15px" },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: "120%",
      letterSpacing: "0.4px",
    },
    button: { fontSize: 13, fontWeight: 500, fontFamily: "Roboto", lineHeight: "24px" },
    overline: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "120%",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 579,
      md: 744,
      lg: 1024,
      xl: 1600,
    },
  },
};

export const scrollerStyles = {
  overflowY: "auto",
  margin: 0,
  padding: 0,
  listStyle: "none",
  "&::-webkit-scrollbar": {
    width: "14px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#C7CED4",
    borderRadius: "8px",
    border: "4px solid transparent",
    backgroundClip: "content-box",
  },
};
