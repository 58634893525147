import qs from "qs";
import { ReferenceVariables } from "../pages/RedirectKeys";
import { AnyObject } from "../types";
import { getSettings } from "../variables";

export function redirectToMicrosoftSignIn(withAccountSelection: boolean, state?: AnyObject) {
  window.location.href = createMicrosoftAuthUrl(true, withAccountSelection, {
    ...state,
    [ReferenceVariables.Idp]: "Microsoft",
  });
}

export function redirectToMicrosoftSignUp(invitationId: string) {
  window.location.href = createMicrosoftAuthUrl(false, false, {
    invitationId,
    [ReferenceVariables.Idp]: "Microsoft",
  });
}

export function createMicrosoftAuthUrl(signin: boolean, withAccountSelection: boolean, state?: AnyObject) {
  const query = qs.stringify({
    ...getMicrosoftAuthParams(signin, withAccountSelection),
  });

  const authUrl = new URL(`${getSettings().MICROSOFT_AUTH_ENDPOINT}?${query}`);

  if (state && Object.keys(state).length > 0) {
    authUrl.searchParams.append("state", JSON.stringify(state));
  }

  return authUrl.toString();
}

function getMicrosoftAuthParams(signin: boolean, withAccountSelection: boolean) {
  return {
    client_id: getSettings().MICROSOFT_CLIENT_ID,
    nonce: "defaultNonce",
    redirect_uri: signin ? getSettings().SIGNIN_REDIRECT_URI : getSettings().SIGNUP_REDIRECT_URI,
    scope: `openid offline_access ${getSettings().PORTAL_ACCESS_SCOPE_MICROSOFT}`,
    response_type: "code",
    ...{ ...(withAccountSelection ? { prompt: "select_account" } : {}) },
  };
}
