import { Alert } from "@mui/material";

interface Props {
  failed: boolean;
  text: string;
}

export default function ErrorComponent({ failed, text }: Props) {
  if (!failed) return <></>;
  return (
    <Alert severity="error" sx={{ flex: 1 }}>
      {text}
    </Alert>
  );
}
