import { Checkbox, FormControlLabel, Link, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PageNames } from "../Entry";
import { api } from "../api/api";
import { ApiError, InvitationResponse } from "../api/types";
import { redirectToMicrosoftSignUp } from "../auth/authHelper";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import useFetch from "../hooks/useFetch";
import { useLocalization } from "../hooks/useLocalization";
import EntriliaLogo from "../icons/EntriliaLogo";
import { ReferenceVariables } from "./RedirectKeys";
import MicrosoftButton from "./common/MicrosoftButton";
import NeedHelp from "./common/NeedHelp";
import StyledPage from "./common/StyledPage";

export default function SignUpPage() {
  const { pages: locale } = useLocalization();
  const [params] = useSearchParams();
  const invitationId = params.get(ReferenceVariables.InvitationId);

  const [validation, error] = useInvitationValidator(invitationId);
  const [agreement, setAgreement] = useState(true);

  const handleRedirectToMicrosoftSignUp = useCallback(
    () => redirectToMicrosoftSignUp(invitationId || ""),
    [invitationId]
  );

  if (invitationId === undefined) {
    return <Navigate to="signin" />;
  }

  if (validation === undefined && error === undefined) {
    return <FullScreenLoader title="" subtitle="" />;
  }

  if (error !== undefined || validation?.isAcceptable === false) {
    return <Navigate to={`/${PageNames.InvalidLink}`} />;
  }

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Stack spacing={2}>
        <Stack alignItems="center">
          <EntriliaLogo />
        </Stack>
        <Stack alignItems="center" pt={2} spacing={1}>
          <Typography variant="h6">{locale.signup.text}</Typography>
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {locale.signup.disclaimer}
          </Typography>
        </Stack>
        <MicrosoftButton onClick={handleRedirectToMicrosoftSignUp} disabled={!agreement} />
        <Stack direction="row" px={1} alignItems="center">
          <FormControlLabel
            sx={{ mr: 1 }}
            control={<Checkbox checked={agreement} size="small" onChange={(_, value) => setAgreement(value)} />}
            label={
              <Stack flexDirection="row">
                <Typography variant="body2" color="text.secondary">
                  {locale.signup.agreement}
                </Typography>
              </Stack>
            }
          />
          <Link href="https://www.entrilia.com/privacy-policy" target="__blank">
            {locale.signup.privacyPolicyLink}
          </Link>
        </Stack>
        <Stack alignItems="center">
          <NeedHelp />
        </Stack>
      </Stack>
    </StyledPage>
  );
}

function useInvitationValidator(invitationId: string | null): [InvitationResponse | undefined, ApiError | undefined] {
  const requestInvitation = useCallback(() => {
    if (invitationId === null) {
      return Promise.reject();
    }
    return api.getInvitation(invitationId);
  }, [invitationId]);

  const [invitationResp, error] = useFetch(requestInvitation);

  return [invitationResp, error];
}
