import React from "react";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { Navigate, useSearchParams } from "react-router-dom";
import { PageNames } from "../Entry";
import { api } from "../api/api";
import { ApiError } from "../api/types";
import useFetch from "../hooks/useFetch";
import { getRedirectUrl } from "../variables";
import { ReferenceVariables } from "./RedirectKeys";

export default function VerifyEmailPage() {
  const [params] = useSearchParams();
  const verificationId = params.get(ReferenceVariables.VerificationId);
  const [verified, error] = useVerificationValidator(verificationId);

  if (verificationId === undefined || verificationId === null) {
    return <Navigate to={`/${PageNames.InvalidLink}`} />;
  }

  if (verified === true) {
    window.location.href = getRedirectUrl();
  }

  if (error?.code === 401) {
    return <Navigate to={buildSignInRedirectUrl(verificationId)} />;
  }
  if (verified === false || error !== undefined) {
    return <Navigate to={`/${PageNames.InvalidLink}`} />;
  }

  return <FullScreenLoader title="" subtitle="" />;
}

function useVerificationValidator(verificationid: string | null): [boolean | undefined, ApiError | undefined] {
  const [success, setSuccess] = React.useState<boolean>();

  const verifyEmail = React.useCallback(() => {
    if (verificationid === null) {
      return Promise.reject();
    }
    return api.verifyEmail(verificationid);
  }, [verificationid]);

  const [, error] = useFetch(verifyEmail, () => setSuccess(true));

  return [success, error];
}

function buildSignInRedirectUrl(verificationId: string) {
  const redirectParams = new URLSearchParams();
  redirectParams.append(ReferenceVariables.VerificationId, verificationId);
  const urlToRedirect = `/${PageNames.SignIn}?${redirectParams.toString()}`;
  return urlToRedirect;
}
