import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { Navigate } from "react-router";
import { api } from "../api/api";
import { getMessageFromResponseError } from "../auth/axiosHelper";
import { useLocalization } from "../hooks/useLocalization";
import NotCompletedVerificationIcon from "../icons/NotCompletedVerificationIcon";
import { authCookieExists } from "../utils/cookies";
import ErrorComponent from "./ErrorComponent";
import StyledPage from "./common/StyledPage";

export default function VerificationNotCompletedPage() {
  const { pages: locale } = useLocalization();

  const [sending, setSending] = React.useState(false);
  const [sendingFailed, setSendingFailed] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const authCookieExist = authCookieExists();

  const resendEmail = React.useCallback(() => {
    setSending(true);
    setError(undefined);
    setTimeout(async () => {
      try {
        await api.resendEmailVerification();
        setSendingFailed(false);
      } catch (error) {
        const responseError = getMessageFromResponseError(error);
        if (responseError) {
          setError(responseError);
        }
        setSendingFailed(true);
      } finally {
        setSending(false);
      }
    }, 0);
  }, [setSending]);

  if (!authCookieExist) {
    return <Navigate to={"/"} />;
  }

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Grid container sx={{ gap: 3 }}>
        <Grid container sx={{ justifyContent: "center" }}>
          <NotCompletedVerificationIcon />
        </Grid>
        <Grid container sx={{ alignItems: "center", flexDirection: "column", pt: 1, gap: 1 }}>
          <Typography variant="h6">{locale.verification_not_completed.header}</Typography>
          <Typography variant="body1" color="secondary" sx={{ textAlign: "center" }}>
            {locale.verification_not_completed.text}
          </Typography>
        </Grid>
        <ErrorComponent failed={!sending && sendingFailed} text={error || locale.emailsent.error} />
        <Grid container sx={{ flexDirection: "column", gap: 1 }}>
          <LoadingButton
            variant="contained"
            sx={{ height: "58px" }}
            disabled={sending}
            loading={sending}
            onClick={resendEmail}
          >
            Send verification link
          </LoadingButton>
        </Grid>
      </Grid>
    </StyledPage>
  );
}
